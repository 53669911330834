<template>
    <div class="tools">
        <el-button @click="this.$router.go(0);" :disabled="getDisabled">{{lang['html.el-button.refresh']}}</el-button>
        <el-button @click="alertTips">{{lang['html.el-button.alertTips']}}</el-button>
        <view-one-click-time style="margin-left: 10px;margin-right: 10px" @update-filter-value="recvFilterNewValue"/>
        <el-button @click="openFilterTime"  :disabled="getDisabled">{{lang['html.el-button.openFilterTime']}}</el-button>
        <el-button @click="outputReport" :disabled="getDisabled">{{lang['html.el-button.outputReport']}}</el-button>
        <el-tag style="margin-left: 5px;margin-right: 5px;">{{lang['html.el-tag.time']}}:{{makeTime}}</el-tag>
        <el-tag type="info">{{lang['html.el-tag.all']}}:{{getReportResultCount}}</el-tag>
    </div>

    <div v-loading="isLoading" :element-loading-text="lang['html.element-loading-text']">
        <div id="viewChart" style="width: 100%;height: 300px"></div>
    </div>

    <el-checkbox v-show="checkboxShow" v-model="isType" :label="lang['html.el-checkbox.label']" size="large" style="text-align: left;" @change="changeType"/>

    <el-table :data="tableData" stripe style="width: 100%" v-loading="isLoading" :element-loading-text="lang['html.el-table.loading']">
        <el-table-column prop="id" :label="lang['html.el-table-column.id']" width="180" />
        <el-table-column prop="object" :label="lang['html.el-table-column.object']" />
        <el-table-column prop="result" :label="lang['html.el-table-column.result']" width="180" />
    </el-table>

<!--    分页组件-->
    <viewPagination
        ref="viewPaginationRef"
        @update-table-data="recvTableData"
    />

<!--    时间筛选器-->
    <viewFilterFromTime
        ref="viewFilterFromTimeRef"
        @update-filter-value="recvFilterNewValue"
    />
</template>

<script>
import * as echarts from 'echarts';
import viewPagination from "./viewPagination";
import viewFilterFromTime from "./viewFilterFromTime";
import viewOneClickTime from "./viewOneClickTime";

export default {
    name: "viewTable",
    components:{
        viewPagination,
        viewFilterFromTime,
        viewOneClickTime,
    },
    data(){
        return {
            //发送请求参数
            postData:{
                type:1, //1 = 包含下级 2 = 不包含下级
                agentUserName:'self',//操作者账号 [self=自己]
                filterType: 2 ,//1 = 启用筛选时间 2 = 不启用
                filterValue:'',
            },
            //表格数据
            tableData:[],
            //报表全部数据
            reportData:[],
            //请求地址
            url:'',
            isLoading:true,
            //数据生成时间
            makeTime : '2000-01-01 00:00:00',
            //数据来源说明
            explain:'',
            //是否包含下级
            isType:true,//[true = 1] 1 = 包含下级 2 = 不包含下级
            //[echarts]句柄
            echartsHandle:{
                dom:null,//dom 句柄
                handle:null,//echarts 句柄
            },
            //[选择框]包含下级 是否显示
            checkboxShow:true,
            lang:this.$lang.getLang('agentStatistics','template.viewTable'),
        }
    },
    computed:{
        //基于[this.isLoading]返回是否禁止状态
        getDisabled(){
            return this.isLoading;
        },
        //获取报表[result字段]相加的总和
        getReportResultCount(){
            return this.reportData.reduce((sum, e) => sum + Number(e.result || 0), 0);
        },
    },
    methods:{
        //初始化
        init(url){
            this.$message('Init..');
            this.url = url;
            this.getReportData();
        },
        //获取统计数据函数
        getReportData(){
            this.isLoading = true;
            this.$notify({message:this.lang['getReportData.wait'],type:'info'});
            this.$api.post(this.url,this.postData).then(res=>{
                this.isLoading = false;
                let data = this.$helper.checkRes(res);
                if (!data){
                    this.$alert(this.lang['getReportData.error.null']);
                    return;
                }
                let reportData = data.data ?? null;
                if (!reportData){
                    // this.$alert('该报表数据为空');
                    return;
                }
                this.reportData = reportData;
                //通知分页组件统计数据已变动
                this.$refs.viewPaginationRef.recvReportData(reportData);
                let time = data.makeTime;
                if (time != null){
                    this.makeTime = time;
                }
            }).catch(err=>{
                this.isLoading = false;
                this.$helper.axiosCatch(err);
            })
        },
        //[分页组件]接收当前显示表格数据
        recvTableData(data){
            this.tableData = data;
            this.renderEcharts(data);
        },
        //取出渲染所需数据
        getEchartsData(data){
            let obj = {
                xAxis:[],
                series:[],
            }
            let value;
            for (let dataKey in data) {
                let temp = data[dataKey];
                value = temp.object;
                if (value != null){
                    obj.xAxis.push(value);
                }

                value = temp.result;
                if (value != null){
                    obj.series.push(value);
                }
            }
            return obj;
        },
        //渲染统计图表
        renderEcharts(data){
            if (this.echartsHandle.dom != null){
                this.echartsHandle.dom.removeAttribute('_echarts_instance_');
                this.echartsHandle.handle.dispose();
                this.echartsHandle.dom = null;
            }
            let dom = this.echartsHandle.dom = document.getElementById("viewChart");
            let handle = this.echartsHandle.handle = echarts.init(dom);
            // console.log('[创建图表]renderEcharts',handle,dom);
            let obj = this.getEchartsData(data);
            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                //[底部]代理账号
                xAxis: {
                    type: 'category',
                    data: obj.xAxis,
                    axisTick: {
                        alignWithLabel: true
                    },
                },
                yAxis: {
                    type: 'value'
                },
                //[显示数据]代理的数据值
                series: [
                    {
                        data: obj.series,
                        type: 'bar',
                        axisTick: {
                            alignWithLabel: true
                        },
                    }
                ]
            };
            handle.setOption(option,true);
            window.onresize = function () { // 自适应大小
                handle.resize();
            };
        },
        //弹出数据说明
        alertTips(){
            this.$alert(this.explain,'Tips',{type: 'info',});
        },
        //[筛选器]打开组件
        openFilterTime(){
            this.$refs.viewFilterFromTimeRef.openFilter();
        },
        //[筛选器]接收新筛选条件
        recvFilterNewValue(time){
            //设置请求参数
            this.postData.filterType = 1;//1 = 启用筛选时间 2 = 不启用
            this.postData.filterValue = time.toString();
            this.$message({message:this.lang['recvFilterNewValue.tips']+time.toString(),type:'success'})
            //重新获取数据
            this.getReportData();
            console.log('recvFilterNewValue',time)
        },
        //是否包含下级选项值变动
        changeType(){
            //[true = 1] 1 = 包含下级 2 = 不包含下级
            let int = this.isType ? 1 : 2;
            this.postData.type = int;
            //重新获取数据
            this.getReportData();
        },
        //导出数据
        outputReport(){
            this.$notify({message:this.lang['outputReport.wait'],type:'info'});
            let report = '';
            let enter = '\r\n';
            for (let reportDataKey in this.reportData) {
                let temp = this.reportData[reportDataKey];
                let arr = [
                    temp.id,temp.object,temp.result
                ];
                report += arr.toString() + enter;
            }
            let header = ['id','object','result'];
            let data = header.toString() + enter + report;
            this.$helper.downLoad(data,'report.csv');
            setTimeout(()=>{
                this.$notify({message:this.lang['outputReport.success'],type:'success'});
            },1000);
        }
    },
    beforeUnmount(){
        //页面销毁前将Echarts销毁,否则容易二次渲染出现空白情况
        if (this.echartsHandle.handle != null){
            this.echartsHandle.handle.setOption({},true);
            this.echartsHandle.handle.dispose();
        }
        let dom = document.getElementById('viewChart');
        if (dom != null){
            dom.removeAttribute('_echarts_instance_');
        }
        // console.log('onBeforeUnmount');
    },
}
</script>

<style scoped>
.tools{
    margin-bottom: 10px;
}
</style>