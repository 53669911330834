快速选择表格组件时间
<template>
    <el-dropdown>
    <el-button class="el-dropdown-link">
      {{lang['html.el-button.filter']}}
      <el-icon class="el-icon--right">
        <arrow-down />
      </el-icon>
    </el-button>
        <template #dropdown>
            <el-dropdown-menu>

<!--                上个月[1号~31号]-->
                <el-dropdown-item
                    @click="submit(getTimeByMonth(getLastMonth(1)))"
                >
                    {{getLastMonth(1) + '月'}}
                </el-dropdown-item>

                <!--                上上个月[1号~31号]-->
                <el-dropdown-item
                    @click="submit(getTimeByMonth(getLastMonth(2)))"
                >
                    {{getLastMonth(2) + '月'}}
                </el-dropdown-item>

                <!--                上上上个月[1号~31号]-->
                <el-dropdown-item
                    @click="submit(getTimeByMonth(getLastMonth(3)))"
                >
                    {{getLastMonth(3) + '月'}}
                </el-dropdown-item>

<!--                年份-->
                <el-dropdown-item @click="submit(makeTimeByYear(2022))" divided>
                    2022
                </el-dropdown-item>
                <el-dropdown-item @click="submit(makeTimeByYear(2021))">
                    2021
                </el-dropdown-item>
                <el-dropdown-item @click="submit(makeTimeByYear(2020))">
                    2020
                </el-dropdown-item>


            </el-dropdown-menu>
        </template>
    </el-dropdown>
</template>

<script>
export default {
    name: "viewOneClickTime",
    emits:[
        'updateFilterValue',//通知父组件筛选条件变动了
    ],
    data(){
        return {
            lang:this.$lang.getLang('agentStatistics','template.viewOneClickTime'),
        }
    },
    methods:{
        /**
         * [月]基于月份生成时间段
         * @param month 月份
         * @returns {string}
         */
        getTimeByMonth(month){
            let date = new Date();
            let year = date.getUTCFullYear();
            //如果提交的月份大于当前月份,则说明是上一年的
            if (month > date.getMonth() +1){
                year = year-1;
            }
            let start = year + '-' + this.$helper.fixZeroStart(month,2) + '-01 00:00:00';
            let end = year + '-' + this.$helper.fixZeroStart(month,2) + '-31 23:59:59';
            return start + ',' + end;
        },
        /**
         * [月]取月份
         * @param num 要减去的月份
         * @returns {number}
         * 返回 减去[num]后的月份
         */
        getLastMonth(num){
            //取出当前月份
            let date = new Date();
            let month = date.getMonth() +1;
            let res = month - num;
            //如果小于0
            if (res <= 0){
                res = res + 12;
            }
            return res;
        },
        //基于年份生成时间段
        makeTimeByYear(year){
            let start = year + '-01-01 00:00:00';
            let end = year + '-12-31 23:59:59';
            return start + ',' + end;
        },
        //通知父组件筛选指定时间段
        submit(time){
            // 将字符串转换成数组
            let arr = time.split(',');
            //通知父组件
            this.$emit('updateFilterValue',arr);
        }
    },

}
</script>

<style scoped>

</style>