<template>
    <el-dialog
        v-model="dialogVisible"
        title="Filter"
        width="30%"
    >
        <el-form>
            <el-form-item :label="lang['html.el-form-item.label']">
                <el-date-picker
                    v-model="filterValue"
                    type="datetimerange"
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    value-format="YYYY-MM-DD HH:mm:ss"
                />
            </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="submit" :disabled="isDisabled">{{lang['html.el-button.filter']}}</el-button>
            <el-button type="primary" @click="dialogVisible = false;clearFrom()" :disabled="isDisabled">{{lang['html.el-button.return']}}</el-button>
            <el-button type="danger" @click="clearFrom()" :disabled="isDisabled">{{lang['html.el-button.reset']}}</el-button>
          </span>
        </template>
    </el-dialog>
</template>

<script>
export default {
    name: "viewFilterFromTime",
    emits:[
        'updateFilterValue',//通知父组件筛选条件变动了
    ],
    data(){
        return {
            dialogVisible:false,
            isDisabled:false,
            //筛选的时间范围
            filterValue:[],
            lang:this.$lang.getLang('agentStatistics','template.viewFilterFromTime'),
        };
    },
    methods:{
        //打开筛选器
        openFilter(){
            this.clearFrom();
            this.dialogVisible = true;
            this.isDisabled  = false;
        },
        //清空表单数据
        clearFrom(){
            this.filterValue = [];
        },
        //筛选数据被点击
        submit(){
            if (this.filterValue.length != 2){
                this.$alert(this.lang['submit.error.length']);
                return;
            }
            //通知父组件筛选条件已变动
            this.dialogVisible = false;
            this.$emit('updateFilterValue',this.filterValue);
        },
    },

}
</script>

<style scoped>

</style>