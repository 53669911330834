<template>
    <el-pagination
        background
        layout="total,prev, pager, next,"
        :total=config.total
        style="margin-top: 10px"
        v-model:currentPage="config.nowPage"
        :page-size=config.size
        @current-change="changePage"
    />
    <el-tag>{{lang['html.el-tag.page']}}: {{config.nowPage}}</el-tag>
</template>

<script>
import paging from "../../../utils/paging";
export default {
    name: "viewPagination",
    emits:[
        'updateTableData',//通知父组件接收表格显示的数据
    ],
    data(){
        return {
            //定义分页参数
            config:{
                nowPage:1,//当前页数
                total:0,//数据总数
                size:20,//每页显示数量
            },
            lang:this.$lang.getLang('agentStatistics','template.viewPagination'),
        }
    },
    methods:{
        //接收父组件传来的数据
        recvReportData(data){
            paging.init(data,this.config.size);
            this.config = {
                nowPage: 1,
                total: data.length,
                size:20,
            };
            this.changePage(1);
        },
        //当前页数变动触发事件
        changePage(page){
            paging.setPage(page);
            this.$notify({message:'[Pages:' + page + '] Render Data',type:'info'});
            //通知父组件接收表格显示的数据
            let tableData = paging.getPageData();
            this.$emit('updateTableData',tableData);
        }
    },
}
</script>

<style scoped>

</style>